<template>
    <div>
        <h3 class="textMD textMedium neutral900 mt-0 mb-10">Продукция</h3>

        <el-row :gutter="20">
            <el-col :sm="12">
                <CRMInfoText
                    head="Продукция"
                    :text="order?.goodId ? getData(order?.goodId, 'good') : '-'"
                />
            </el-col>
            <el-col :sm="12">
                <CRMInfoText
                    head="Спецификация"
                    :text="info?.specGoodId ? getData(info?.specGoodId, 'good') : '-'"
                />
            </el-col>
        </el-row>

        <hr/>

        <h3 class="textMD textMedium neutral900 mt-15 mb-15">Доставка</h3>

        <el-row :gutter="20">
            <el-col :sm="12">
                <CRMInfoText
                    head="Тип доставки"
                    :text="info?.deliveryType ? getInfo(info.deliveryType, deliveryTypes) : '-'"
                />
                <CRMInfoText
                    head="Тариф"
                >
                    <template #text>
                        <template v-if="info?.serviceTariff">
                            <el-button @click="openServiceDetails" type="text" class="textSM textRegular neutral900 pt-0 pb-0" style="height: auto !important;">{{ info.serviceTariff?.name }}</el-button>
                        </template>
                        <template v-else>-</template>
                    </template>
                </CRMInfoText>
                <CRMInfoText
                    head="Объект"
                    :text="info?.deliveryAddress ?? '-'"
                />
                <CRMInfoText
                    head="Наличие гидролотка"
                    :text="info.gidrolotok ? 'Нужен' : 'Не требуется'"
                />
                <CRMInfoText
                    head="Заводы отгрузки"
                    :text="mixers"
                />
            </el-col>
            <el-col :sm="12">
                <CRMInfoText
                    head="Время первой доставки"
                    :text="info.firstTimeDelivery ? moment.unix((info.firstTimeDelivery)).utc().tz(this.moduleTimezone).format('HH:mm') : '-'"
                />
                <CRMInfoText
                    head="Тип интервала"
                    :text="getIntervalName()"
                />
                <CRMInfoText
                    v-if="info.intervalType !== 'withoutInterval'"
                    head="Интервал"
                    :text="info.timeIntervalClient"
                />
                <CRMInfoText
                    head="Бесплатное время на разгрузку"
                    :text="info.timeFreeUnloading ?? '-'"
                />
                <CRMInfoText
                    head="Макс. объём миксера"
                    :text="info.maxVolume ?? '-'"
                />
                <CRMInfoText
                    head="Макс. осей миксера"
                    :text="info.maxAxle ?? '-'"
                />
            </el-col>
        </el-row>

        <hr/>

        <h3 class="textMD textMedium neutral900 mt-15 mb-15">Комментарии</h3>

        <el-row :gutter="20">
            <el-col :sm="12">
                <CRMInfoText
                    head="Комментарий водителю"
                    :text="info?.commentDriver ?? '-'"
                />
                <CRMInfoText
                    head="Комментарий оператору"
                    :text="info?.commentOperator ?? '-'"
                />
            </el-col>
            <el-col :sm="12">
                <CRMInfoText
                    head="Комментарий диспетчеру"
                    :text="info?.commentDispatch ?? '-'"
                />
                <CRMInfoText
                    head="Комментарий лаборатории"
                    :text="info?.commentLab ?? '-'"
                />
            </el-col>
        </el-row>

        <template v-if="order.pump">
            <hr/>

            <h3 class="textMD textMedium neutral900 mt-15 mb-15">Насос</h3>

            <el-row :gutter="20">
                <el-col :sm="12">
                    <CRMInfoText
                        head="Авто"
                        :text="info?.pump?.name ?? '-'"
                    />
                    <CRMInfoText
                        head="Гос.номер"
                        :text="info?.pump?.number ?? '-'"
                    />
                    <CRMInfoText
                        head="Длина"
                        :text="info?.pump?.pump_length ?? '-'"
                    />
                </el-col>
                <el-col :sm="12">

                </el-col>
            </el-row>
        </template>

        <hr/>

        <h3 class="textMD textMedium neutral900 mt-15 mb-15">Стоимость</h3>

        <el-row :gutter="20">
            <el-col :sm="12">
                <CRMInfoText
                    head="Метод оплаты"
                    :text="info?.paymentMethod ? getInfo(info?.paymentMethod, paymentMethods) : '-'"
                />
            </el-col>
            <el-col :sm="12">
                <CRMInfoText
                    head="Стоимость продукции"
                    :text="info?.productPrice ? formatNumber(info.productPrice) + ' ₽' : '-'"
                />
                <CRMInfoText
                    head="Стоимость доставки"
                    :text="info?.deliveryPrice ? formatNumber(info.deliveryPrice) + ' ₽' : '-'"
                />
                <CRMInfoText
                    head="Предоплата"
                    :text="info?.prepayment ? formatNumber(info.prepayment) + ' ₽' : '-'"
                />
            </el-col>
        </el-row>

        <hr/>

        <h3 class="textMD textMedium neutral900 mt-15 mb-15">Дополнительно</h3>

        <el-row :gutter="20">
            <el-col :sm="12">
                <CRMInfoText
                    head="Поставщик"
                    :text="info?.seller ?? '-'"
                />
                <CRMInfoText
                    head="Перевозчик"
                    :text="info?.carrier ?? '-'"
                />
            </el-col>
            <el-col :sm="12">
                <CRMInfoText
                    head="Конструктив"
                    :text="info?.constructive ?? '-'"
                />
            </el-col>
        </el-row>

        <div class="mt-30 mb-15">
            <el-button @click="edit" type="primary">Редактировать</el-button>
            <el-button @click="remove">Удалить</el-button>
        </div>
    </div>

    <el-dialog
        v-model="serviceDetailsDialog"
        title="Подробности тарифа"
        :width="mobile ? '100%' : '80%'"
        :destroy-on-close="true"
    >
        <ServiceTariffDetails
            :id="serviceId"
        />
    </el-dialog>
</template>

<script>

import iconsMixin from "@/mixins/iconsMixin";
import CRMInfoText from "@/views/components/MixOrderGroup/components/order/CRMInfoText.vue";
import paymentMethods from "@/mixins/paymentMethods";
import functionsMixin from "@/mixins/functionsMixin";
import {httpService} from "@/services/http.service";
import moment from "moment";
import ServiceTariffDetails from "@/views/settings/serviceTariffs/components/ServiceTariffDetails.vue";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";

export default {
    name: 'OrderInfo',
    components: {ServiceTariffDetails, CRMInfoText},
    mixins: [iconsMixin, paymentMethods, functionsMixin, mobileCheckMixin],
    props: ['order', 'info'],
    data() {
        return {
            deliveryTypes: [{
                id: 'delivery',
                name: 'Доставка'
            }, {
                id: 'take-away',
                name: 'Самовывоз'
            }],
            serviceId: null,
            serviceDetailsDialog: false,
        }
    },
    inject: ['api'],
    computed: {
        moment() {
            return moment
        },
        ...mapState(useCommonStore, {
            modules: 'getModules'
        }),
        mixers() {
            let mixersData = this.modules.find(el => el.accountModuleId == this.$route.params.id)?.mixers
            let mixers = []

            this.info?.mixerIds?.forEach(id => {
                mixers.push(mixersData.find(el => el.id == id))
            })

            return mixers.map((mixer, index) => {
                if (index === mixers.length - 1) {
                    return mixer.name
                } else {
                    return mixer.name + ', '
                }
            }).join('')
        }
    },
    methods: {
        edit() {
            if (this.$route.meta.moduleKey === 'dispatch') {
                this.$router.push(
                    {
                        name: 'DispatchOrderGroupsUpdate',
                        params: {
                            id: this.$route.params.id,
                            groupId: this.order.id,
                        }
                    }
                );
            } else if (this.$route.meta.moduleKey === 'crm') {
                this.$router.push(
                    {
                        name: 'CRMOrderGroupsUpdate',
                        params: {
                            id: this.$route.params.id,
                            groupId: this.order.id,
                        }
                    }
                );
            }
        },
        getIntervalName() {
            let name = 'Без интервала'
            if (this.info.intervalType === 'withInterval') {
                name = 'С интервалом на разгрузку'
            } else if (this.info.intervalType === 'withIntervalClient') {
                name = 'С интервалом от клиента'
            }
            return name
        },
        openServiceDetails() {
            this.serviceId = this.info?.serviceTariff?.id
            this.serviceDetailsDialog = true
        },
        remove() {

            this.$confirm('Вы действительно удалить заказ?', 'Подтвердите удаление', {
                confirmButtonText: 'Удалить',
                cancelButtonText: 'Отмена',
                type: 'warning'
            }).then(() => {
                this.loading = true

                httpService().post(this.api.mixOrderGroup.removeOrder, {
                    account_modules_id: this.$route.params.id,
                    module_key: this.$route.meta.moduleKey,
                    orderId: this.order.id
                }).then((response) => {
                    let data = response.data

                    if (data.success) {
                        this.$message({
                            message: 'Заказ удален',
                            showClose: true,
                            type: 'success'
                        })

                        window.setTimeout(() => {
                            this.$router.push({ name: (this.$route.params.key === 'crm' ? 'CRMOrders' : 'DispatchIndex'),  params:{ id: this.$route.params.id }})
                        }, 150)
                    }
                }).catch((error) => {
                    this.$message({
                        message: error.response.data.message,
                        showClose: true,
                        type: 'error'
                    })
                    this.loading = false
                })
            })
        },
    }
}
</script>

<style scoped>

</style>
