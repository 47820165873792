<template>
    <el-tooltip
        effect="dark"
        raw-content
        placement="left"
        :show-after="500"
    >
        <template #content>
            <div>Отгружается: {{ `${progress.deliveredTotal} м³` }}</div>
            <div>Завершено: {{ `${progress.doneTotal} м³` }}</div>
            <div>Общий объем: {{ `${total} м³` }}</div>
        </template>

        <div class="order-quantity w-100">
            <div class="order-quantity-total d-flex-full textXS textMedium" v-if="progress.donePercent < 100">
                <div class="cutOverflow">
                    <span class="primary600">{{ progress.deliveredTotal }} /</span> <span
                    class="success400">{{ progress.doneTotal }} /</span> <span class="neutral300">{{ total }} м³</span>
                </div>
                <div class="neutral300">
                    {{ progress.deliveredPercent }}%
                </div>
            </div>
            <div class="order-quantity-total success400 textXS" v-else>
                <div class="d-flex-full">
                    <div>
                        Завершено
                    </div>
                    <div>
                        {{ `${progress.doneTotal} м³` }}
                    </div>
                </div>
            </div>
            <ProgressMultiBar :percent1="progress.deliveredPercent" :percent2="progress.allDonePercent"/>
        </div>
    </el-tooltip>
</template>
<script>

import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: 'OrderQuantity',
    components: {},
    props: {
        total: Number,
        orders: Array,
        withoutWidth: Boolean
    },
    mixins: [functionsMixin],
    computed: {
        progress() {

            const deliveredTotal = this.orders.filter(el => (el.status !== 'new' && el.status !== 'done'))
                .reduce((totalOrder, item) => totalOrder + Math.abs(item.total), 0)

            const ordersTotal = this.orders.reduce((accumulator, currentItem) => {
                return accumulator + parseFloat(currentItem.total, 10);
            }, 0) - deliveredTotal;

            const doneTotal = this.orders.filter(el => el.status === 'done')
                .reduce((totalOrder, item) => totalOrder + Math.abs(item.total), 0)

            const allDoneTotal = this.orders.filter(el => el.status !== 'new')
                .reduce((totalOrder, item) => totalOrder + Math.abs(item.total), 0)

            const ordersDone = this.orders.filter(el => el.status === 'done')
                .reduce((totalOrder, item) => totalOrder + Math.abs(item.total), 0)

            let distributed = (ordersTotal / this.total) * 100
            let delivered = (deliveredTotal / this.total) * 100
            let done = (ordersDone / this.total) * 100
            let unallocated = 100 - (distributed + delivered)
            let allDone = (allDoneTotal / this.total) * 100

            return {
                unallocated: unallocated,
                distributed: distributed,
                deliveredTotal: deliveredTotal ? this.formatNumber(deliveredTotal, 2) : 0,
                doneTotal: doneTotal ? this.formatNumber(doneTotal, 2) : 0,
                deliveredPercent: delivered ? this.formatNumber(delivered) : 0,
                donePercent: done ? this.formatNumber(done) : 0,
                allDonePercent: allDone ? this.formatNumber(allDone) : 0,
            }
        },
    },
}
</script>
<style scoped lang="scss">

.order-quantity {

    &__total {
        text-align: center;
        color: #22252C;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        padding: 4px 0px;
    }
}
</style>
<script setup>
import ProgressMultiBar from "@/views/components/ProgressMultiBar.vue";
</script>
