<template>
    <div class="textRegular textXS neutral300">
        <div class="d-flex-full content-center ">
            <div class="d-flex-full-stroke">
                                <span>
                                    {{
                                        orderGroupData.firstOrderTimeDelivery ? moment.unix((orderGroupData.firstOrderTimeDelivery)).utc().tz(this.moduleTimezone).format('HH:mm') : '-'
                                    }}
                                </span>
                <template v-if="orderGroupData.intervalType !== 'withoutInterval'">
                    <svg class="buyer-circle" height="3" viewBox="0 0 5 4" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.28954 2.058C4.28954 2.30067 4.24754 2.52933 4.16354 2.744C4.07954 2.95867 3.95821 3.14533 3.79954 3.304C3.65021 3.46267 3.46354 3.59333 3.23954 3.696C3.02487 3.78933 2.77754 3.836 2.49754 3.836C2.21754 3.836 1.96554 3.78933 1.74154 3.696C1.52687 3.59333 1.34021 3.46267 1.18154 3.304C1.03221 3.14533 0.915539 2.95867 0.831539 2.744C0.747539 2.52933 0.705539 2.30067 0.705539 2.058C0.705539 1.82467 0.747539 1.60067 0.831539 1.386C0.915539 1.162 1.03221 0.970666 1.18154 0.812C1.34021 0.653333 1.52687 0.527333 1.74154 0.434C1.96554 0.331333 2.21754 0.28 2.49754 0.28C2.77754 0.28 3.02487 0.331333 3.23954 0.434C3.46354 0.527333 3.65021 0.653333 3.79954 0.812C3.95821 0.970666 4.07954 1.162 4.16354 1.386C4.24754 1.60067 4.28954 1.82467 4.28954 2.058Z"
                            fill="#8a909a"/>
                    </svg>
                    <span>
                                    {{ getIntervalName(orderGroupData) }}
                                </span>
                </template>
            </div>
            <div class="d-flex" style="gap: 5px">
                <el-tooltip
                    :show-after="500"
                    v-if="orderGroupData.pump || orderGroupData.pump_vehicle_id"
                    placement="top"
                    content="Требуется насос"
                >
                    <div
                        style="line-height: 20px !important;"
                        :class="['group-order-pump__item', { 'group-order-pump__item--active': (orderGroupData.pump || orderGroupData.pump_vehicle_id)}]">
                        Н
                    </div>
                </el-tooltip>
                <el-tooltip
                    :show-after="500"
                    v-if="orderGroupData.gidrolotok"
                    placement="top"
                    content="Требуется гидролоток"
                >
                    <div
                        style="line-height: 20px !important;"
                        :class="['group-order-pump__item', { 'group-order-pump__item--active': orderGroupData.gidrolotok}]">
                        Г
                    </div>
                </el-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: "MixOrderGroupTableOrderIntervalBlock",
    props: ['orderGroup'],
    mixins: [functionsMixin],
    computed: {
        moment() {
            return moment
        },
        orderGroupData() {
            return this.orderGroup
        }
    },
    methods: {
        getIntervalName(order) {
            let name = 'Без интервала'
            if (order.intervalType === 'withInterval') {
                name = 'С интервалом на разгрузку'
            } else if (order.intervalType === 'withIntervalClient') {
                name = 'С интервалом от клиента - ' + order.timeIntervalClient + ' мин.'
            }
            return name
        },
    }
}
</script>

<style scoped>

</style>
