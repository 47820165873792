<template>
    <Auth title="Рабочее место оператора">
            <div class="operator-container">

            <div class="operator-header text-right">
                <!-- <el-link @click="doLogout">
                    Выйти
                </el-link> -->
            </div>

            <div class="operator-body">

                <el-row :gutter="20">
                    <el-col :sm="18">

                        <TableHeadBlock
                            v-model="search"
                            :loading="loading"
                            :icon="icons.plus"
                            :disable-filter="true"
                            @moduleGet="moduleGet(true)"
                            :disable-create-button="operatorCreateOrder ? false : true"
                            @openCreateValueDrawer="openCreateMixOrderDialog"
                            tooltip-text="Добавить отгрузку"
                        >
                            <template #afterSearchAndFilter>
                                <el-radio-group class="operatorOrdersTab" v-model="ordersStatus">
                                    <el-radio-button label="В работе" value="В работе"/>
                                    <el-radio-button label="Выполненные" value="Выполненные"/>
                                    <el-radio-button label="Все" value="Все"/>
                                </el-radio-group>

                                <div
                                    class="operatorDates"
                                >
                                    <day-list-filter
                                        :date="currentDate"
                                        @onChangeDate="changeDate"
                                    ></day-list-filter>
                                </div>
                            </template>
                            <template v-slot:buttonsBlock v-if="operatorPrint">
                                <button
                                    @click="() => printerDialogShow = true"
                                    v-if="!mobile"
                                    class="ci-btn ci-btn_default"
                                    :disabled="values.length === 0"
                                >
                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke="currentColor"
                                            d="M6 17H4C3.44772 17 3 16.5523 3 16L3 8C3 7.44772 3.44772 7 4 7L20 7C20.5523 7 21 7.44771 21 8V16C21 16.5523 20.5523 17 20 17H18"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path stroke="currentColor"
                                            d="M7 21L17 21C17.5523 21 18 20.5523 18 20V16C18 15.4477 17.5523 15 17 15L7 15C6.44772 15 6 15.4477 6 16L6 20C6 20.5523 6.44772 21 7 21Z"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path stroke="currentColor" d="M10 11H14" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                        <path stroke="currentColor"
                                            d="M7 7L17 7C17.5523 7 18 6.55229 18 6V4C18 3.44772 17.5523 3 17 3L7 3C6.44772 3 6 3.44772 6 4L6 6C6 6.55229 6.44772 7 7 7Z"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <span v-if="windowWidth > 1500">Печать</span>
                                </button>
                            </template>
                        </TableHeadBlock>


                        <div v-loading="loading" style="min-height: 520px">

                            <div class="mt-30" v-if="orders.length">
                                <OperatorTable
                                    :orders="orders"
                                    :mixerCount="mixerCount"
                                    :operatorCheckTimeStart="operatorCheckTimeStart"
                                    :factory-name="factoryName"
                                    :tz="tz"
                                    @openMixOrderAction="openMixOrderAction"
                                />
                            </div>

                            <div class="text-center" style="padding-top: 150px; padding-bottom: 150px"
                                v-else-if="!loading && !firstLoad">
                                <div>
                                    <EmptyListIcon :height="160"/>
                                    <div>
                                        <h3 class="neutral700">Отгрузок не найдено</h3>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </el-col>
                    <el-col :sm="6">

                        <div class="card operator-mixers">
                            <div class="card-body">
                                <OperatorMixers
                                    :mix-id="mixId"
                                    :tz="tz"
                                />
                            </div>
                        </div>
                    </el-col>
                </el-row>

            </div>
        </div>
    </Auth>

    <el-dialog
        v-model="orderDetailsDialog"
        :title="'Отгрузка' + (orderDetails.doc ? ' №' + orderDetails.doc : '')"
        :width="mobile ? '100%' : '60%'"
        :destroy-on-close="true"
    >
        <OperatorOrderDetails
            :order="orderDetails"
            :mix-id="mixId"
        />
    </el-dialog>

    <el-dialog
        v-model="orderActionDialog"
        :title="'Отгрузка' + (orderDetails.doc ? ' №' + orderDetails.doc : '')"
        :width="mobile ? '100%' : '60%'"
        :destroy-on-close="true"
    >
        <OperatorOrderAction
            :order="orderDetails"
            :mix-id="mixId"
            @update="moduleGet"
            @close="orderActionDialogClose()"
        />
    </el-dialog>

    <el-dialog
        title="Создание отгрузки"
        v-model="createMixOrderDialog"
        :width="mobile ? '100%' : '60%'"
        direction="rtl"
        :size="drawerSize"
        :destroy-on-close="true">

        <OperatorOrderCreate
            :mix-id="mixId"
            @update="moduleGet"
            @close="orderCreateDialogClose()"
        />

    </el-dialog>

    <el-dialog
        v-model="printerDialogShow"
        title="Печать документов"
        :width="drawerSize"
        :destroy-on-close="true"
    >
        <OperatorPrintDocs
            :orders="values"
            :mix-id="mixId"
            :date="currentDate"
        />
    </el-dialog>
</template>

<script>

import {authService} from '@/services/auth.service'
import {mapState, mapStores} from 'pinia'
import {useCommonStore} from '@/store/common'
import Auth from '@/views/layouts/Auth.vue';
import {httpService} from '@/services/http.service'
import functionsMixin from '@/mixins/functionsMixin'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import TableHeadBlock from '@/views/components/Table/TableHeadBlock.vue'
import iconsMixin from '@/mixins/iconsMixin'
import OperatorOrderAction from "@/views/components/Dashboards/Operator/OperatorOrderAction.vue";
import mixOrderStatuses from "@/mixins/mixOrderInfo";
import OperatorTable from "./OperatorTable.vue";
import OperatorMixers from "@/views/components/Dashboards/Operator/OperatorMixers.vue";
import OperatorOrderDetails from "@/views/components/Dashboards/Operator/OperatorOrderDetails.vue";
import OperatorOrderCreate from "@/views/components/Dashboards/Operator/OperatorOrderCreate.vue";
import {useEchoStore} from "@/store/Echo";
import mixOrderInfo from "@/mixins/mixOrderInfo";
import EmptyListIcon from "@/views/components/Icons/EmptyListIcon.vue";
import moment from "moment";
import DayListFilter from "@/views/components/Table/DayListFilter.vue";
import OperatorPrintDocs from "@/views/components/Dashboards/Operator/OperatorPrintDocs.vue";

export default {
    name: "OperatorDashboard",
    components: {
        OperatorPrintDocs,
        DayListFilter,
        EmptyListIcon,
        OperatorOrderCreate,
        OperatorTable,
        OperatorOrderDetails, OperatorMixers, OperatorOrderAction, TableHeadBlock, Auth
    },
    inject: ['api'],
    mixins: [functionsMixin, mobileCheckMixin, iconsMixin, mixOrderStatuses, mixOrderInfo],
    data() {
        return {
            loading: false,
            printerDialogShow: false,
            firstLoad: true,
            search: null,
            ordersStatus: 'В работе',
            moduleId: null,
            module: {
                integrations: [],
                moduleId: null,
                module: {
                    id: null
                }
            },
            mixId: null,
            tableData: [],
            orderDetails: {
                doc: null
            },
            orderDetailsDialog: false,
            orderActionDialog: false,
            createMixOrderDialog: false,
            filter: {},
            values: [],
            filtered: [],
            mixIntegrations: [],
            operatorCreateOrder: false,
            operatorCheckTimeStart: false,
            operatorPrint: false,
            currentDate: moment(),
            tz: 'Europe/Moscow',
            factoryName: null,
            mixCount: 0,
            mixerCount: 0,
        }
    },
    async created() {
        if (this.links.length > 0) {
            this.mixId = this.links.find(el => el.type === 'mix').module_id
        }

        await this.mixModuleGet()

        this.moduleGet(true)

        // this.counterInterval = setInterval(
        //     function () {
        //         if (!this.orderDetailsDialog && !this.orderActionDialog && !this.createMixOrderDialog) this.moduleGet()
        //     }.bind(this), 30000)
    },
    beforeUnmount: function () {
        window.Echo.leave(`laravel_database_ChannelMixOrderCreateNew`);
        window.Echo.leave(`laravel_database_ChannelMixOrderDeletedNew`);
        window.Echo.leave(`laravel_database_ChannelMixOrderUpdateNew`);
        clearInterval(this.counterInterval)
    },
    computed: {
        ...mapState(useCommonStore, {
            links: 'getNav'
        }),
        ...mapStores(useEchoStore),
        orders() {
            this.filterOrdersByStatus();
            this.filterOrdersBySearch();
            return this.filtered;
        }
    },
    methods: {
        filterOrdersByStatus() {
            this.filtered = this.values;
            if (this.ordersStatus === 'В работе') {
                this.filtered = this.values.filter(el => el.status === 'new' || el.status === 'loading');
            } else if (this.ordersStatus === 'Выполненные') {
                this.filtered = this.values.filter(el => el.confirm && (el.status !== 'new' && el.status !== 'loading'));
            }
        },
        filterOrdersBySearch() {
            if (this.search) {
                this.filtered = this.filtered.filter(data => !this.search || (data.orderGood.toLowerCase().includes(this.search.toLowerCase()) || data.vehicle.toLowerCase().includes(this.search.toLowerCase())));
            }
        },
        changeDate(date) {
            this.currentDate = moment(date).utcOffset(0, true).startOf('day')
            this.moduleGet(true)
        },
        async mixModuleGet() {
            await httpService().post(this.api.operator.getModule).then((response) => {
                let data = response.data

                if (data.success) {
                    this.module = data
                    this.moduleId = data.moduleId
                    this.operatorCreateOrder = data.settings.operatorCreateOrder ?? false
                    this.operatorCheckTimeStart = data.settings.operatorCheckTimeStart ?? false
                    this.operatorPrint = data.settings.operatorPrint ?? false

                    this.factoryName = data.module.integrations.find((i) => i.integration.key === 'SmartMix')?.product ?? null;
                    console.log(data.module.integrations)

                    window.Echo.channel('laravel_database_ChannelMixOrderCreateNew')
                        .listen(`.MixOrderCreate.${this.module.module.id}`, () => {
                            this.moduleGet()
                        });
                }
            })
        },
        openMixOrderDetails(index, row) {
            this.orderDetailsDialog = true
            this.orderDetails = row
        },
        openMixOrderAction(row) {
            this.orderActionDialog = true
            this.orderDetails = row
        },
        orderActionDialogClose() {
            this.orderActionDialog = false
            this.orderDetails = {
                doc: null
            }
        },
        orderCreateDialogClose() {
            this.createMixOrderDialog = false
        },
        openCreateMixOrderDialog() {
            this.createMixOrderDialog = true
        },
        async moduleGet(loading) {
            if (loading) this.loading = true;
            try {
                const response = await httpService().post(this.api.operator.getOrders, {
                    date: this.currentDate
                });
                window.Echo.leave(`laravel_database_ChannelMixOrderDeletedNew`);
                window.Echo.leave(`laravel_database_ChannelMixOrderUpdateNew`);

                let data = response.data;
                if (data.success) {
                    this.values = data.values.sort((a, b) => new Date(b.start_at) - new Date(a.start_at)); // на беке же есть сортировка?
                    this.mixCount = data.mixCount;
                    this.mixerCount = data.mixerCount;
                    this.tz = data.tz

                    this.values.forEach((item) => {
                        window.Echo.channel('laravel_database_ChannelMixOrderDeletedNew')
                            .listen(`.MixOrderDeleted.${item.id}`, () => {
                                this.moduleGet()
                            })

                        window.Echo.channel('laravel_database_ChannelMixOrderUpdateNew')
                            .listen(`.MixOrderUpdate.${item.id}`, () => {
                                this.moduleGet()
                            });
                    })

                    this.firstLoad = false

                    window.setTimeout(() => {
                        this.loading = false;
                    }, 300)
                }

            } catch (error) {
                this.loading = false;
            }
        },
        filterValues() {

        },
        doLogout() {
            this.$confirm(
                'Вы действительно хотите выйти?',
                'Подтвердите выход из системы',
                {
                    confirmButtonText: 'Да, выйти',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    authService().doLogout()
                })
                .catch(() => {
                    this.$message({
                        message: 'Отменено',
                        showClose: true,
                        type: 'info',
                    });
                    this.loading = false;
                })
        }
    },
}
</script>

<style scoped>

</style>
